
import ContactImage from '../img/contactus.jpeg'
const Contact = () => {
  return (
    <div className="container contact">
      {/* <div className="hero-container" style={{backgroundImage: `url(${ContactImage})`}}></div> */}
      <img className="header" src={ContactImage} alt="Contact Us" />
      <h1 className="title">Drop us a line!</h1>
      <p>
        Let's get talking. You can contact us directly, or fill out the form and we will get back to you promptly.
      </p>
      <form className="cf" action="https://formsubmit.co/admin@mihrealty.com" method="POST">
        <input type="hidden" name="_next" value="http://mihrealty.com/thanks" />
        <input type="hidden" name="_subject" value="New Contact Page Submission" />
        <input type="text" name="_honey" style={{ display: "none" }} />
        <div className="half left cf">
          <input name="name" type="text" id="input-name" placeholder="Name" />
          <input name="email" type="email" id="input-email" placeholder="Email" />
          <input name="title" type="text" id="input-subject" placeholder="Subject" />
        </div>
        <div className="half right cf">
          <textarea name="message" type="text" id="input-message" placeholder="Tell us what we can do to support your real estate goals."></textarea>
        </div>
        <input type="submit" value="Submit" id="input-submit" />
      </form>
    </div>
  )
}

export default Contact