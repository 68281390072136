
import ReactDOM  from "react-dom/client";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";

import Layout from "./utilities/Layout"
import Home from "./pages/Home";
import Team from "./pages/Team";
import'./index.scss'
import { AppProvider } from "./utilities/App.Context";
import Contact from "./pages/Contact";
import Mortgage from "./pages/Mortgage";
import Property from "./pages/Property";
import Thanks from "./pages/Thanks";

const root = ReactDOM.createRoot(
    document.getElementById("root")
  );

const App = () => {
    return (
        <>
        <AppProvider>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path="/" index element={<Home />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/propertymanagement" element={<Property />} />
                        <Route path="/mortgage" element={<Mortgage />} />
                        <Route path="/contactus" element={<Contact />} />
                        <Route path="/thanks" element={<Thanks />} />
                        <Route path = "*" element={<Navigate to="/"/>} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </AppProvider>
        </>
    )
}

root.render(<App />)