import { useContext, useState ,createContext } from 'react'

const AppContext = createContext()

export const useApp = () => {
    return useContext(AppContext)
}

export const AppProvider = ({children})=>{
    
  const [checked,setChecked] = useState(false)

  const value = {
    checked,
    setChecked
  }

  return(
    <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>
  )


}