import React from 'react'
import PropMan from '../img/propinves.jpeg'
import { IoFastFood, IoCash, IoHome, IoBusiness } from 'react-icons/io5'

const Property = () => {
  return (
    <>
    <div className="hero-container property" >
        <span className="hero-title">Property Management</span>

        <img className="header" src={PropMan} alt="Property Management" />
    </div>
    <div className="container team">
      <h1 className="title">Above and Beyond...</h1>
      <div className="image-wrapper">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        We realize you have other options when it comes to property management for your property investment. We know that our clients choose us because we go above and beyond, offering property owners and tenants alike the best possible service. We strive to build a great relationship between both our tenants and our clients, acting as a professional and caring liaison.
        </p>
      </div>
    </div>
    <div className="container loans">
      <div className="image-wrapper">
      <p>We offer services for:</p>
      </div>
        <div className="loan-programs">
            <div className="loan-program">
                <IoHome />
                <span className="loan-name">Residential Housing</span>
            </div>
            <div className="loan-program">
                <IoBusiness />
                <span className="loan-name">Apartment Homes</span>
            </div>
            <div className="loan-program">
                <IoCash />
                <span className="loan-name">Coroporate Housing</span>
            </div>
            <div className="loan-program">
                <IoFastFood />
                <span className="loan-name">Mixed-Used Retail Space</span>
            </div>
        </div>
    </div>
    <div className="container team">
      <h1 className="title">Stay up to date!</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        With the local market knowledge so important to each property's success. We have the very best real estate professionals backing us to keep current; We’re specially trained to think like an owner and take pride in resident satisfaction.
        </p>
        <br />
        <p>
        Prospective residents can easily search for the perfect rental through the RentCafe website, which offers up-to-date real estate listings and detailed information about HPM managed properties.
        </p>
      </div>
    </div>
    </>
  )
}

export default Property