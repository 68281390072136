import React from 'react'
import AboutUs from '../../img/aboutus.jpg'


const About = () => {
  return (
    <div className="container about" id="aboutus">
    <h1 className="title">Welcome,</h1>
    <div className="catchphrase">"Here at <span className="highlight">MIHRealty</span>, We <span className="highlight">M</span>ake <span className="highlight">I</span>t <span className="highlight">H</span>appen!"</div>
    <div className="aboutussection">
      {/* <img src={AboutUs} alt="About Us" /> */}
      <p>MIH realty is a premier Houston Real Estate firm. Owned and operated by Stanley Mani, a well-renowned real estate broker, and adviser. For more than two decades, he learned tirelessly the ins and outs of the real estate growth and business. With his exceptional experience and communication skills, he built networks of professionals to help him become the top and thus, came up with his own company, the MIH (Make It Happen) Realty
        <br />
        <br />
        Customers who choose MIH Realty know they are going to have the opportunity to work with professional and skilled realtors that have over a decade of experience in the industry. Our agents are passionate about providing our clients with top-quality residential and commercial real estate services. We seek to make our client’s dreams come true! Whether you are interested in buying a new home, seeking a real estate investment, or have questions regarding Mortgage, we have the knowledge to provide stellar assistance every step of the way.
        <br />
        <br />
        Our team of dedicated agents is eager to help you achieve your goals. Reach out to us today, we’ll be very pleased to begin to build an important relationship with you as our client. When you choose MIH Realty you hire a team that is focused on making your dreams come true. Contact us today so that we can discuss your needs and start the exciting process of helping your dreams become a reality. MIH realty will be your partner in your financial dreams. We will be with you every step of the way. Your dream is our dream. If you are determined, reach out to us, and let’s Make It Happen (MIH).
        <br />
        <br />
        <span>- MIHRealty</span>
      </p>
    </div>
  </div>
  )
}

export default About