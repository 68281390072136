import { useEffect, useRef } from 'react'
import HeroImage from '../img/hero-option3.png'
import HeroImageTexas from '../img/hero-option3-texasv.png'
import { useApp } from '../utilities/App.Context'
import About from './subpages/About'
import JoinUs from './subpages/JoinUs'

const Home = () => {

  useEffect(()=>{
    document.getElementById("wrapper-elem").addEventListener('scroll',(e)=>{
      if(document.getElementById("HeroImageRef")){
        document.getElementById("HeroImageRef").style.transform = `translateY(${e.target.scrollTop}px)`
      }
    })
  },[])

  return (
    <>
    <div className="hero container">
      <div className="hero-box">
      <h1>Only the <span>best deals.</span></h1>
      <p>There's much to see here. So, take your time, look around,
        and learn all there is to know about us.</p>
        <div className="box-wrapper">
          <img id={"HeroImageRef"} className="bg-wrapper" src={HeroImageTexas}></img>
          {/* <img id={"HeroImageRef"} className="bg-wrapper still" src={HeroImageSketch}></img> */}
        </div>
        <a className="view-listings" href="#aboutus">Learn More</a>
        <button className="view-listings back"></button>
      </div>
      </div>
      <About />
      <JoinUs />
      {/* <div className="container"></div> */}
    </>
  )
}

export default Home