const JoinUs = () => {
  return (
    <div className="container joinus">
      <h1 className="title">Join MIHRealty!</h1>
      <form className="cf" action="https://formsubmit.co/admin@mihrealty.com" method="POST">
        <input type="hidden" name="_next" value="http://mihrealty.com/thanks" />
        <input type="hidden" name="_subject" value="New Join Us Page Submission" />
        <input type="text" name="_honey" style={{ display: "none" }} />
        <div className="half left cf" >
          <input name="name" type="text" id="input-name" placeholder="Name" />
          <input name="email" type="email" id="input-email" placeholder="Email" />
          <input name="phone" type="number" id="input-phone" placeholder="Phone Number" />
        </div>
        <div className="half right cf">
          <input name="city" type="text" id="input-city" placeholder="City" />
          <select name="status" id="input-status">
            <option value="partTime">Part-time</option>
            <option value="fullTime">Full-time</option>
            <option value="newLicense">Newly Licensed</option>
            <option value="noLicense">Not yet Licensed</option>
          </select>
          {/* <textarea name="message" type="text" id="input-message" placeholder="Tell us what we can do to support your real estate goals."></textarea> */}
        </div>
        <input type="submit" value="Submit" id="input-submit" />
      </form>
    </div>
  )
}

export default JoinUs