import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useApp } from "./App.Context"
import Footer from "./Footer"
import Navigation from "./Navigation"
import RespNavigation from "./RespNavigation"

const Layout = ({children}) => {

  const {checked,setChecked} = useApp()
  const location = useLocation()

  useEffect(() => {
    setChecked(false)
    // console.log(location)
  },[location])

  return (
    <div className="wrapper" id="wrapper-elem">
      <section>
        <div className={`resp-nav ${checked ? "show" : null}`}>
          <RespNavigation />
        </div>
        <Navigation />
              {children}
        <Footer />
        <div className="container-spacer"></div>
      </section> 
    </div>
  )
}

export default Layout