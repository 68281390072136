import React from 'react'
import TeamImage from '../img/team-fixed.png'
import agentPhoto from '../img/stanleymani.jpg'
import agentPhoto1 from '../img/sademartinez.png'
import agentPhoto2 from '../img/sunilvarghese.png'
import agentPhoto3 from '../img/tejgowda.png'

import { IoArrowForwardCircleSharp, IoCallSharp, IoMail } from 'react-icons/io5'
import JoinUs from './subpages/JoinUs'

const Team = () => {

  return (
    <>
      <div className="hero-container" style={{ backgroundImage: `url(${TeamImage})` }}></div>
      <div className="container team">
        <h1 className="title">Meet our team.</h1>
        <div className="image-wrapper">
          {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
          <p>
            Choosing the right real estate professional to work with is essential. At MIH REALTY, LLC, you can search from  among their agents to find just the right person to help you find your dream home. You can filter the agent  roster by their ratings, name, city or zip code, REALTOR® designations or any particular language or cultural  preference. You can leave your number for an agent to call you back right from the roster and even favorite an   agent you've enjoyed working with in the past. HAR is committed to providing you the right tools to find the  right agent for all your real estate needs.
          </p>
        </div>
      </div>
      <div className="container roster-title">
        <h1 className="title">Administration</h1>
      </div>
      <div className="container roster">
        <div className="agent">
          <img src={agentPhoto} alt="Stanley Mani" />
          <span className="name">Stanley </span>
          <span className="post">Broker of Record & President</span>
          <div className="agent-socials">
            <a className="social-btn" href="tel:713-446-5086">
              <IoCallSharp style={{ color: "#F25A51" }} />
            </a>
            <a className="social-btn" href="mailto:stanley@mihrealty.com">
              <IoMail style={{ color: "#F25A51" }} />
            </a>
          </div>
        </div>
        {/* <div className="agent">
          <img src={agentPhoto1} alt="Sade Martinez" />
          <span className="name">Sade Martinez</span>
          <span className="post">Office Manager</span>
          <div className="agent-socials">
            <a className="social-btn" href="tel:832-874-2832">
              <IoCallSharp style={{color: "#F25A51"}} />
            </a>
            <a className="social-btn" href="mailto:officemanager@mihrealty.com">
              <IoMail style={{color: "#F25A51"}} />
            </a>
          </div>
        </div> */}
        <div className="agent">
          <img src={agentPhoto2} alt="Sunil Varghese" />
          <span className="name">Sunil Varghese</span>
          <span className="post">Agent Supervisor</span>
          <div className="agent-socials" href="tel:281-236-8979">
            <a className="social-btn">
              <IoCallSharp style={{ color: "#F25A51" }} />
            </a>
            <a className="social-btn" href="mailto:sunil@mihrealty.com">
              <IoMail style={{ color: "#F25A51" }} />
            </a>
          </div>
        </div>
        <div className="agent">
          <img src={agentPhoto3} alt="Tej Gowda" />
          <span className="name">Tej Gowda</span>
          <span className="post">Administrator</span>
          <div className="agent-socials">
            <a className="social-btn" href="tel:346-933-0284">
              <IoCallSharp style={{ color: "#F25A51" }} />
            </a>
            <a className="social-btn" href="mailto:admin@mihrealty.com">
              <IoMail style={{ color: "#F25A51" }} />
            </a>
          </div>
        </div>
      </div>
      <div className="container agents-title">
        <h1 className="title">Our Agents</h1>
        <p>Visit our amazing roster of agents at HAR.com!</p>
        <button className="visit-agents" onClick={() => { window.open("https://www.har.com/MIHO01/mih-realty-llc_agents?sort=name", '_blank') }} >Visit Agents <IoArrowForwardCircleSharp /></button>
      </div>
      <JoinUs />
    </>
  )
}

export default Team