import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import Logo from "../img/Logo.png"
import { useApp } from "./App.Context"

const Navigation = () => {

    const {checked,setChecked} = useApp()

    const handleOnChange = (event) =>{
        setChecked(event.target.checked)
    }

    useEffect( () => {
        if(!checked){
            let checkbox = document.getElementById("respnavbtn")
            checkbox.checked = false
        }
    },[checked])

  return (
    <nav>
    <img src={Logo} alt="MIH Realty" className="nav-logo" />
    <div className="nav-links">
      <NavLink className={({isActive}) => isActive ? "selected": null} to="/">home</NavLink>
      <NavLink className={({isActive}) => isActive ? "selected": null} to="/propertymanagement">property management</NavLink>
      <NavLink className={({isActive}) => isActive ? "selected": null} to="/mortgage">mortgage</NavLink>
      <NavLink className={({isActive}) => isActive ? "selected": null} to="/team">our team</NavLink>
      <NavLink className={({isActive}) => isActive ? "selected": null} to="/contactus">contact us</NavLink>
      <label className="toggle">
        <input id="respnavbtn" type="checkbox" onChange={()=>{handleOnChange(event)}} />
        <div>
            <div>
                <span></span>
                <span></span>
            </div>
            <svg>
                <use xlinkHref="#path" />
            </svg>
            <svg>
                <use xlinkHref="#path" />
            </svg>
        </div>
    </label>
            
    <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="path">
            <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
        </symbol>
    </svg>
    </div>
  </nav>
  )
}

export default Navigation