import { NavLink } from "react-router-dom"

const RespNavigation = () => {
  return (
    <>
        <NavLink className="resp-link" to="/">Home</NavLink>
        <NavLink className="resp-link" to="/propertymanagement">Property Management</NavLink>
        <NavLink className="resp-link" to="/mortgage">Mortgage</NavLink>
        <NavLink className="resp-link" to="/team">Our Team</NavLink>
        <NavLink className="resp-link" to="/contactus">Contact Us</NavLink>
    </>
  )
}

export default RespNavigation