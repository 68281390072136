import React from 'react'

const Thanks = () => {
  return (
    <>
        <div className="container team">
      <h1 className="title">Thank you!</h1>
      <div className="image-wrapper">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
            Thank you considering us! We'll get back to you as soon as possible.
        </p>
      </div>
    </div>
    </>
  )
}

export default Thanks