import React from 'react'
import MortgageImage from '../img/mortgage.jpeg'

import agentPhoto from '../img/stanleymani.jpg'
import agentPhoto1 from '../img/arunmathew.png'

import secMort from '../img/secmort.png'

import { IoArrowForwardCircleSharp, IoCallSharp,IoMail } from 'react-icons/io5'

import { IoCard, IoCash, IoHandLeft, IoHome, IoKey } from 'react-icons/io5'

const Mortgage = () => {
  return (
    <>
    <div className="hero-container mortgage" style={{backgroundImage: `url(${MortgageImage})`}}>
        {/* <span className="hero-title">Secure Mortgage</span> */}
        {/* <img src={secMort} alt="Secure Mortgage" /> */}
    </div>
     <div className="container team">
      <h1 className="title">The Key To Home Loans.</h1>
      <div className="image-wrapper">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        Our mission is to help families and individuals meet their housing goals by providing quality residential mortgage products and services. Although we work with Lenders, Builders, Real Estate Agents and more, we never lose sight of who our ultimate customer is…you, the Home Buyer.
        </p>
      </div>
    </div>
     <div className="container loans">
      <h1 className="title">Our Loan Programs</h1>
      <div className="image-wrapper">
      <p>Our loan originators have access to a full array of competitively priced loan programs:</p>
      </div>
        <div className="loan-programs">
            <div className="loan-program">
                <IoHome />
                <span className="loan-name">Federal Housing Authority (FHA)</span>
            </div>
            <div className="loan-program">
                <IoKey />
                <span className="loan-name">Veterans' Affairs (VA)</span>
            </div>
            <div className="loan-program">
                <IoHandLeft />
                <span className="loan-name">Conventional Mortgages</span>
            </div>
            <div className="loan-program">
                <IoCash />
                <span className="loan-name">Home Equity Loans</span>
            </div>
            <div className="loan-program">
                <IoCard />
                <span className="loan-name">Mortgage Credit Certificate</span>
            </div>
        </div>
    </div>
    <div className="container team mort">
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        Our loan originators have access to a full array of competitively priced loan programs:
        </p>
      </div>
    </div>
    <div className="container team mort">
      <h1 className="title">Federal Housing Authority</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        The Federal Housing Authority (FHA) allows low- and moderate-income families to buy a home by offering them lower down-payment requirements and lower interest rates.
        </p>
      </div>
    </div>
    <div className="container team mort">
      <h1 className="title">Veterans' Affairs</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        VA Loans are provided by the US Department of Veterans' Affairs for veterans of the Armed Services. These also have more lenient requirements for borrowers who qualify.
        </p>
      </div>
    </div>
    <div className="container team mort">
      <h1 className="title">Conventional Mortgages</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        In conventional mortgages, part of each month's payment goes towards paying off the principal and part goes toward interest. Conventional mortgages include fixed rate mortgages, in which the interest rate is the same throughout the life of the loan, or adjustable rate mortgages (ARM), in which the interest rate can vary.
        </p>
      </div>
    </div>
    <div className="container team mort">
      <h1 className="title">USDA</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        USDA Home Loans provide for home purchase or refinance. These loans are guaranteed by the USDA and are serviced by direct lenders that meet federal guidelines. There are many qualifying factors for a USDA home loan, things such as property location, income limits and loan amount may limit eligibility.
        </p>
        <br />
        <p>
          Conforming-Both fixed and ARM<br />
          Jumbo-Both fixed and ARM<br />
          2nd home and investment property<br />
        </p>
      </div>
    </div>
    <div className="container team mort">
      <h1 className="title">Home Equity Loans (Cash-Out)</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        A consumer loan secured by a first mortgage, allowing home owners to borrow against their equity in the home. The loan is based on the difference between the homeowner's equity and the home's current market value.
        </p>
      </div>
    </div>
    <div className="container team">
      <h1 className="title">Mortgage Credit Certificate</h1>
      <div className="image-wrapper props">
        {/* <img className="team-image" src={TeamImage} alt="Team" /> */}
        <p>
        In the United States, a Mortgage Credit Certificate (more commonly referred to as MCC) is a certificate issued by certain state or local governments that allows a taxpayer to claim a tax credit for some portion of the mortgage interest paid during a given tax year. The MCC program is designed to help first-time homebuyers offset a portion of their mortgage interest on a new mortgage as a way to help homebuyers qualify for a loan. Because it is a tax credit and not a tax deduction, mortgage lenders will often use the estimated amount of the credit on a monthly basis as additional income to help the potential borrower qualify for the loan. Certain limitations including income will apply.
        </p>
      </div>
    </div>
    <div className="container roster-title">
      <h1 className="title">Let Us Help!</h1>
    </div>
    <div className="container roster secure">
        <div className="agent">
          <img src={agentPhoto} alt="Stanley Mani" />
          <span className="name">Stanley Mani</span>
          <span className="post">NMLS #70160</span>
          <div className="agent-socials">
            <a className="social-btn" href="tel:713-446-5086">
              <IoCallSharp style={{color: "#F25A51"}} />
            </a>
            <a className="social-btn" href="mailto:smani@securemortgagecompany.com">
              <IoMail style={{color: "#F25A51"}} />
            </a>
          </div>
        </div>
        <div className="agent">
          <img src={agentPhoto1} alt="Arun Mathew" />
          <span className="name">Arun Mathew</span>
          <span className="post">NMLS #70160</span>
          <div className="agent-socials">
            <a className="social-btn" href="tel:832-289-0914">
              <IoCallSharp style={{color: "#F25A51"}} />
            </a>
            <a className="social-btn" href="mailto:amathew@securemortgagecompany.com">
              <IoMail style={{color: "#F25A51"}} />
            </a>
          </div>
        </div>
    </div>
    </>
  )
}

export default Mortgage